import { Button } from "@chakra-ui/react";
import React from "react";

export default function PrimaryButton({ title, onClickHandler, ...rest }) {
  return (
    <Button
      onClick={onClickHandler}
      {...rest}
      bgColor={"blue.500"}
      color="white"
      _hover={{ bg: "blue.600" }}
    >
      {title}
    </Button>
  );
}
