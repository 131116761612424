import {
  Box,
  Link,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { Fade } from "react-awesome-reveal";
import Button from "../components/Button";

export default function LandingpageGuidelines() {
  return (
    <Fade cascade>
      <Box
        bgColor="gray.50"
        py={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          w="80%"
          py={8}
          bgColor="gray.50"
          color="gray.500"
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ base: "center", md: "flex-start" }}
        >
          <Text fontSize="4xl" minW="25%">
            GUIDELINES & RULES
          </Text>
          <UnorderedList
            spacing={8}
            fontSize="lg"
            pl={{ base: 0, md: 16 }}
            mt={2}
          >
            <ListItem>
              Team should consist of a maximum of 1-3 participants.
            </ListItem>
            <ListItem>
              Each team can work on one or both of the problem statements.
            </ListItem>
            <ListItem>
              Participants should be interested in working on internships or
              full time positions in healthcare.
            </ListItem>
            <ListItem>
              Eligibility
              <OrderedList>
                <ListItem>
                  Candidates should be enrolled in a bachelor’s/master’s
                  programme and should have past experience in coding/software
                  development.
                </ListItem>
                <ListItem>
                  Candidates should be available to present their team solution
                  in the final event of the hackathon.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              The competition will be divided into two phases:
              <OrderedList>
                <ListItem>
                  Brainstorming and idea/solution submission phase (Feb 7th -
                  Feb 25th)
                </ListItem>
                <ListItem>
                  Development and idea submission phase (Feb 25th - Mar 15th)
                </ListItem>
              </OrderedList>
            </ListItem>
          </UnorderedList>
        </Box>
        
      </Box>
    </Fade>
  );
}
