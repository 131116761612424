import { Box } from "@chakra-ui/react";
import React from "react";
import LandingpageFooter from "../components/LandingpageFooter";
import LandingpageGuidelines from "../components/LandingpageGuidelines";
import LandingpageHeader from "../components/LandingpageHeader";
import LandingpageProblemstatement from "../components/LandingpageProblemstatement";
import PrizesSection from "../components/PrizesSection";
import LandingpageThemeSection from "../components/LandingpageThemeSection";

export default function LandingPage() {
  return (
    <Box>
      <LandingpageHeader />

      <PrizesSection />

      <LandingpageThemeSection />

      {/* <LandingpageParticipationSection /> */}

      <LandingpageProblemstatement />

      <LandingpageGuidelines />

      <LandingpageFooter />
    </Box>
  );
}
