import React from "react";
import { Box, Text, Link, Button } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Fade } from "react-awesome-reveal";
import PrimaryButton from "../components/Button";

export default function LandingpageProblemstatement() {
  return (
    <Fade cascade>
      <Box
        bgColor="gray.50"
        display="flex"
        flexDirection="column"
        alignItems="center"
        pb={1}
      >
        <Box
          w="80%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          borderRight={"2px"}
          borderRightColor="blue.500"
        >
          <Text mt={12} fontSize="4xl" color="blue.500">
            PROBLEM STATEMENT
          </Text>
          <Text p={8} fontSize="lg" color="gray.500" mt={8}>
            HR Managers at many organisations are looking for a solution to
            promote employees’ health and wellness whilst working from home
            (WFH) and in other settings. You are supposed to build an
            interactive digital solution (For ex. Chatbot,AI based assistant,
            etc..) for the employees that regularly check in with employees and
            assesses their mental health
          </Text>
          <Link
            href="https://docs.google.com/document/u/3/d/e/2PACX-1vTBTF660Ttt3m9xQ7ml7lqERPMADHpqqZp1r9L_7rWkfXVPYO_GAHoodoPOGak_I3l2NzJxsj81VxXa/pub"
            color="#199edd"
            pos="relative"
            top="10px"
            right="44px"
            isExternal
          >
            See details <ExternalLinkIcon mx="2px" />
          </Link>
          <hr
            style={{
              borderBottom: "1px solid #199edd",
              width: "50%",
              alignSelf: "flex-end",
            }}
          />
          <Text p={8} fontSize="lg" color="gray.500" mt={8}>
            Data about health and recent health trends is enormous and
            omnipresent. But getting trusted and filtered news and updates about
            critical trends is hard. In today's fast paced environment it
            becomes challenging for anyone to keep up with the market. You are
            supposed to assist and help an editor of a leading digital health
            magazine. You are responsible to help them make better decisions
            about what health trends to write about and which stories to cover.
          </Text>
          <Link
            href="https://docs.google.com/document/u/3/d/e/2PACX-1vTBTF660Ttt3m9xQ7ml7lqERPMADHpqqZp1r9L_7rWkfXVPYO_GAHoodoPOGak_I3l2NzJxsj81VxXa/pub"
            color="#199edd"
            pos="relative"
            top="8px"
            right="44px"
            isExternal
          >
            See details <ExternalLinkIcon mx="2px" />
          </Link>
          <hr
            style={{
              borderBottom: "1px solid #199edd",
              width: "50%",
              alignSelf: "flex-end",
            }}
          />
        </Box>
        <Box
          mt={2}
          display={"flex"}
          flexDirection={{ base: "column", md: "row" }}
          w="40%"
          justifyContent={"space-around"}
          alignItems={"center"}
        >
          <PrimaryButton
            as={Link}
            // href="https://jvtft23alw7.typeform.com/to/UWNaM8nE"
            target="_blank"
            mt={{ base: 1, md: 4 }}
            title="Registration Closed"
            readOnly
            disabled
          />
          <Button
            as={Link}
            color={"blue.500"}
            href="https://form.typeform.com/to/ic5KY6g7?typeform-source=hackathon.medpiper.com"
            target="_blank"
            _hover={{
              bgColor: "transparent",
            }}
            variant={"outline"}
            borderColor={"blue.500"}
            borderWidth={2}
            mt={{ base: 1, md: 4 }}
          >
            Submit Your Idea
          </Button>
        </Box>
      </Box>
    </Fade>
  );
}
