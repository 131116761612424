import { Box, ListItem, OrderedList, Text, Link } from "@chakra-ui/react";
import React from "react";
import { Fade } from "react-awesome-reveal";
import Button from "../components/Button";

export default function LandingpageThemeSection({ title, content }) {
  return (
    <Fade cascade>
      <Box
        p={{ base: 8, md: 20 }}
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-between"
        alignItems={{ base: "center", md: "flex-start" }}
      >
        <Text textAlign={"center"} fontSize="4xl" color="gray.600" minW="25%">
          THEME
        </Text>
        <Box pl={{ base: 0, md: 16 }}>
          <Text fontSize="lg" color="gray.400">
            We at MedPiper are solving the trust and verification issues in the
            global healthcare ecosystem, one country, one medical establishment,
            and one healthcare practitioner at a time. We believe that
            healthcare practitioners and connecting them seamlessly to the rest
            of the ecosystem are very important.
          </Text>
          <Text fontSize="lg" color="gray.400" mt={8}>
            We invite you to hack around and become a part of the MedPiper
            mission. Join us, as we innovate on behalf of doctors and the
            healthcare ecosystem. We are working on cutting-edge solutions to
            bring healthcare to everyone by solving trust and matching issues in
            the global healthcare ecosystem, at scale.
          </Text>
          <Text mt={8} fontSize="lg" color="gray.400">
            As part of this hackathon, you will be working on helping:
          </Text>
          <OrderedList mt={8} fontSize="lg" color="gray.400">
            <ListItem>
              HR managers bring better physical and mental health to their
              employees.
            </ListItem>
            <ListItem>
              Healthcare writers to bring about important healthcare news and
              topics so readers can be upto date.
            </ListItem>
            
          </OrderedList>
        </Box>

      </Box>
    </Fade>
  );
}
