import { Box, HStack, Image, Link, Text } from "@chakra-ui/react";
import React from "react";
import BgImage from "../assets/HACKATHON.jpg";
import BgImageMobile from "../assets/hackathon-mobile.jpg";
import Button from "../components/Button";
import HeaderButton from "../components/HeaderButton";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Media from "react-media";

export default function LandingpageHeader() {
  return (
    <Media
      queries={{
        small: "(max-width: 599px)",
        medium: "(min-width: 600px) and (max-width: 1199px)",
        large: "(min-width: 1200px)",
      }}
    >
      {(matches) => (
        <Box>
          {matches.small && <Mobile />}
          {matches.medium && <Desktop />}
          {matches.large && <Desktop />}
        </Box>
      )}
    </Media>
  );
}

function Mobile() {
  return (
    <Box pos="relative">
      <Image src={BgImageMobile} w="100%" h="auto" />
      <Box
        w={{ base: "75%", md: "35%" }}
        maxW={{ base: "75%", md: "70%" }}
        color="white"
        pos="absolute"
        top={{ base: "30px", md: "40px" }}
        left={{ base: 6, md: 20 }}
      >
        <Box>
          <Box>
            <Text mb={0} color={"gray.200"}>
              Unlock new age healthcare
            </Text>
            <Text
              mb={0}
              fontSize={{ base: "xl", md: 60 }}
              position={"relative"}
              bottom={{ base: 2, md: 4 }}
              fontWeight="bold"
            >
              HACKEMIST
            </Text>
          </Box>
        </Box>
        <Box mt={{ base: 1, md: 2 }} w="100%">
          <Box
            display={"flex"}
            flexDirection={{ md: "row" }}
            w="100%"
            justifyContent={"space-between"}
            alignItems={{ base: "flex-start", md: "center" }}
          >
            <Box>
              <Text color={"gray.200"} fontSize={{ base: "md", md: "2xl" }}>
                Ideation phase
              </Text>
              <Text>Feb 7th - Feb 25th</Text>
            </Box>
            <Box>
              <Text color={"gray.200"} fontSize={{ base: "md", md: "2xl" }}>
                Development phase
              </Text>
              <Text>Feb 25th - Mar 15th</Text>
            </Box>
          </Box>
        </Box>
        <Box mt={{ base: 2, md: 8 }} w="100%">
          <Box>
            <Text color={"white"}>STEP 1</Text>
            <Text color={"gray.200"}>Registration Closed</Text>
            <HeaderButton
              as={Link}
              // href="https://docs.google.com/forms/d/e/1FAIpQLSd9iT5CrtD-ZX_tzER5SwnPYXME2K5rwIyH_igT7dBH_BC6yg/viewform"
              target="_blank"
              title="Registration Closed"
              readOnly
              disabled
              mt={{ base: 1, md: 4 }}
            />
          </Box>
          <Box mt={8}>
            <Text color={"white"}>STEP 2</Text>
            <Text color={"gray.200"}>
              If registered, submit your pitch before 25th February
            </Text>

            <HeaderButton
              as={Link}
              href="https://docs.google.com/forms/d/e/1FAIpQLSfikUugDbVTfU32WC4ttwkPpmzvLNIPESSJ63Ej3pT3j-2Lgw/viewform"
              target="_blank"
              title="Submit Your Idea"
              mt={{ base: 1, md: 4 }}
            />
          </Box>
        </Box>
      </Box>
      <HStack
        alignItems={"center"}
        pos="absolute"
        top={{ base: "80px", md: "680px" }}
        left={{ base: 6, md: 20 }}
        color={"white"}
        cursor={"pointer"}
        display={{ base: "none", md: "flex" }}
        onClick={() =>
          window.scrollTo({
            top: 900,
            left: 0,
            behavior: "smooth",
          })
        }
      >
        <ChevronDownIcon />
        <Text fontWeight={"medium"}>See prizes and more details</Text>
      </HStack>
    </Box>
  );
}
function Desktop() {
  return (
    <Box pos="relative">
      <Image src={BgImage} w="100%" h="auto" />
      <Box
        w={{ base: "75%", md: "35%" }}
        maxW={{ base: "75%", md: "70%" }}
        color="white"
        pos="absolute"
        top={{ base: "30px", md: "40px" }}
        left={{ base: 6, md: 20 }}
      >
        <Box>
          <Box>
            <Text mb={0} color={"gray.200"}>
              Unlock new age healthcare
            </Text>
            <Text
              mb={0}
              fontSize={{ base: "xl", md: 60 }}
              position={"relative"}
              bottom={{ base: 2, md: 4 }}
              fontWeight="bold"
            >
              HACKEMIST
            </Text>
          </Box>
        </Box>
        <Box mt={{ base: 1, md: 2 }} w="100%">
          <Box
            display={"flex"}
            flexDirection={{ md: "row" }}
            w="100%"
            justifyContent={"space-between"}
            alignItems={{ base: "flex-start", md: "center" }}
          >
            <Box>
              <Text color={"gray.200"} fontSize={{ base: "md", md: "2xl" }}>
                Ideation phase
              </Text>
              <Text>Feb 7th - Feb 25th</Text>
            </Box>
            <Box>
              <Text color={"gray.200"} fontSize={{ base: "md", md: "2xl" }}>
                Development phase
              </Text>
              <Text>Feb 25th - Mar 15th</Text>
            </Box>
          </Box>
        </Box>
        <Box mt={{ base: 2, md: 8 }} w="100%">
          <Box>
            <Text color={"white"}>STEP 1</Text>
            <Text color={"gray.200"}>Registration Closed</Text>
            <HeaderButton
              as={Link}
              // href="https://docs.google.com/forms/d/e/1FAIpQLSd9iT5CrtD-ZX_tzER5SwnPYXME2K5rwIyH_igT7dBH_BC6yg/viewform"
              target="_blank"
              title="Registration Closed"
              readOnly
              disabled
              mt={{ base: 1, md: 4 }}
            />
          </Box>
          <Box mt={8}>
            <Text color={"white"}>STEP 2</Text>
            <Text color={"gray.200"}>
              If registered, submit your pitch before 25th February
            </Text>
            <HeaderButton
              as={Link}
              href="https://docs.google.com/forms/d/e/1FAIpQLSfikUugDbVTfU32WC4ttwkPpmzvLNIPESSJ63Ej3pT3j-2Lgw/viewform"
              target="_blank"
              title="Submit Your Idea"
              mt={{ base: 1, md: 4 }}
            />
          </Box>
        </Box>
      </Box>
      <HStack
        alignItems={"center"}
        pos="absolute"
        top={{ base: "80px", md: "680px" }}
        left={{ base: 6, md: 20 }}
        color={"white"}
        cursor={"pointer"}
        display={{ base: "none", md: "flex" }}
        onClick={() =>
          window.scrollTo({
            top: 900,
            left: 0,
            behavior: "smooth",
          })
        }
      >
        <ChevronDownIcon />
        <Text fontWeight={"medium"}>See prizes and more details</Text>
      </HStack>
    </Box>
  );
}
