import { Button } from "@chakra-ui/react";
import React from "react";

export default function HeaderButton({ title, ...rest }) {
  return (
    <Button
      _hover={{
        bgColor: "transparent",
      }}
      variant={"outline"}
      {...rest}
      color="white"
      borderColor={"white"}
      borderWidth={2}
    >
      {title}
    </Button>
  );
}
