import React from "react";
import { Box, Divider, Text } from "@chakra-ui/react";
import PrizeCard from "./PrizeCard";

const PrizesArray = [
    {
        number: "1",
        title: "₹ 50000",
    },
    {
        number: "2",
        title: "₹ 25000"
    },
    {
        number: "3",
        title: "₹ 10000",
    },
];

export default function PrizesSection() {

    return (
        <Box p={{ base: 2, md: 8 }} display="flex" flexDirection="column" alignItems="center" >
            <Text fontSize="4xl" color="gray.600" >PRIZES</Text>
            <Box p={2} mt={4} display="flex" flexDirection={{ base: "column", md: "row" }} w="100%" justifyContent="space-around" alignItems="center" >
                {
                    PrizesArray.map((prize, index) => <PrizeCard key={index} prize={prize} />)
                }
            </Box>
            <Box ml={{ base: 12, md: 32 }} w="100%" display={"flex"} flexDir={"row"} alignItems={"center"}  >
                <Divider w={{ base: "30%", md: "40%" }} />
                <Text p={2} >There's more</Text>
                <Divider w={{ base: "30%", md: "40%" }} />
            </Box>
            <Box p={2} mt={4} display="flex" flexDirection={{ base: "column", md: "row" }} w="80%" justifyContent="space-around" alignItems="center" >
                <Box w={{ base: "100%", md: "28%" }} p={4} mt={{ base: 2, md: 0 }} fontSize={24}  >
                    <Text color="gray.300" >
                        All registered participants will get {' '}
                        <Text color="gray.500" as="span" textDecoration={"underline"} >
                            Dr. Swasth HealthFin Cards.
                        </Text>
                    </Text>
                </Box>
                <Box w={{ base: "100%", md: "28%" }} p={4} mt={{ base: 2, md: 0 }} fontSize={24} textAlign={"center"} >
                    <Text color="blue.500" >
                        &
                    </Text>
                </Box>
                <Box w={{ base: "100%", md: "32%" }} p={4} mt={{ base: 2, md: 0 }} fontSize={24} >
                    <Text color="gray.300" >
                        The top 5 runner up teams can explore {' '}
                        <Text color="gray.500" as="span" textDecoration={"underline"} >
                            interview opportunities with us.
                        </Text>
                    </Text>
                </Box>
            </Box>
        </Box>
    );
}