import React from 'react'
import { Box, Text, Image, VStack } from "@chakra-ui/react";
import PrizeGraphic from "../assets/prize.svg";

function PrizeCard({ prize }) {
    return (
        <Box mt={{ base: 2, md: 0 }} w={{ base: "100%", md: "25%" }} p={2} display="flex" flexDirection="column" alignItems={"center"} justifyContent={"space-around"} >
            <Box pos={"relative"} >
                <Image src={PrizeGraphic} />
                <Text color={"gray.500"} pos={"absolute"} top={8} left={"44px"} fontWeight={"bold"} fontSize={28}  >{prize.number}</Text>
            </Box>
            <VStack alignItems={"center"} mt={8} >
            <Text fontSize={48}  color={"blue.500"} fontWeight="md" >{prize.title}</Text>
            <Text color={"gray.300"} >Cash prize</Text>
            </VStack>
        </Box>
    )
}

export default PrizeCard
